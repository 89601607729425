<template>
  <div class="pufa-rule-page">
    <div class="head">
      <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152624842-6.png" alt="">
    </div>
    <div class="content">
      <div class="activty-text">
        <div class="star-icon">
          <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152708588-9.png" alt="">
        </div>
        <div class="activty-title">一、活动名称：</div>
        <div class="text">新客特享，专属福利送不停</div>
      </div>
      <div class="activty-text">
        <div class="activty-title">二、活动时间： </div>
        <div class="text">2022年5月9日起至2022年6月30日 </div>
        <div class="red-bg">
          <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506152653197-8.png" alt="">
        </div>
      </div>
      <div class="activty-text">
        <div class="activty-title">三、活动对象：</div>
        <div class="text">活动期间通过有鱼记账APP指定推送页面成功申请并获批的浦发银行信用卡主卡的新客户（以下简称“持卡人”）。
          新客户指满足从未持有浦发银行信用卡或参加本活动（即提交信用卡申请）时已注销所有浦发银行信用卡12月（含）以上的主卡客户。</div>
      </div>
      <div class="activty-text">
        <div class="activty-title">四、活动内容：</div>
        <div class="text">活动期间，通过有鱼记账APP指定页面，成功申请浦发银行信用卡主卡的新客户，
          并于核卡通过后60天内使用该卡任意合格消费1笔，即可获得如下权益：（每位新客户限参与1次）<br />
          ①获得1年有鱼记账APP会员权益<br />
          ②获得50元现金红包<br />
          <span>奖励发放：</span>奖励将于客户满足消费条件后的次月底前发放，
          其中有鱼记账APP会员权益发放至其有鱼记账APP账户；50元现金红包发放至有鱼记账APP-我的-钱包。
        </div>
      </div>
      <div class="activty-text">
        <div class="activty-title">五、其他条款：  </div>
        <div class="text">
          <p>1、活动期间，同一新客户限获取一次权益。同一有鱼账户ID、同一手机号码，
            满足以上任一条件均视为同一客户。</p>
          <p>2、客户在活动期间申请了两张或以上浦发银行信用卡的，以在活动期间成功通过审核的第一张信用卡为准，其他信用卡不参加本活动。</p>
          <p>3、本活动中的“合格消费”指有积分的消费，包括一般签账消费，但不包括房地产类、汽车销售类、批发类、医院类、学校类、慈善机构类、
            代扣代缴类、公共事业费、船只销售类、法庭费用类、政府机构类、大型企业批发类、烟草配送类、保险销售类、保险业和保险金类、直销类、当铺类、
            县乡优惠－房产汽车类、县乡优惠－批发类、县乡优惠－超市加油类、三农商户类、金融机构类消费，以及第三方支付账户付款消费；不包括预借现金、
            “万用金”分期还款、银行费用（包括信用卡年费、信用卡利息、预借现金手续费、逾期还款违约金等）、其他各类依《上海浦东发展银行信用卡（个人卡）章程》
            及《上海浦东发展银行信用卡（个人卡）领用合约》约定的费用以及上海浦东发展银行信用卡中心规定的其他项目。消费后如发生退货、冲正、争议、虚假、
            退税或撤销等交易，将不计入本活动所指的合格消费。</p>
          <p>4、消费时间以银行系统记录的入账时间为准。若因商户未及时向银行请款结算或因卡组织或收单行原因导致无法识别的境外交易，恕不计入此活动统计。</p>
          <p>5、如参与本活动的客户存在任何利用系统漏洞、外挂软件、插件等手段或工具套取活动奖励、损害活动规则、违背活动公平原则、违背诚信原则的行为的，
            有鱼记账APP有权取消该客户参与本活动及获得本活动奖励的资格，并有权取消或收回已发放的活动奖励。浦发银行信用卡中心有权对活动中的恶意套利行为进行鉴定。</p>
          <p>6、如参与本活动的客户恶意注册、利用本次活动优惠进行非法牟利、炒作、套现、转售、虚假交易的，
            有鱼记账APP有权取消其享受本活动优惠的资格并取消或收回其因参加本活动所获商品、权益或其他奖励。</p>
          <p>7、客户知悉互联网存在诸多不确定性。若系统漏洞、故障或其他原因致使系统无法正常运行，导致客户获得与本活动规则不符的奖励，
            有鱼记账APP保留取消或收回客户所获非正常奖励的权利。如因不可抗力、意外因素以及活动中存在大面积作弊行为、
            通讯路线故障或者计算机大规模瘫痪等原因致使难以继续开展本活动的，
            有鱼记账APP有权修改、暂停或取消本活动。
            如因任何非有鱼记账APP原因导致活动无法正常进行的，有鱼记账APP不承担任何相关责任。</p>
          <p>8、出现所持信用卡过期、被停用、管制、客户自行注销信用卡、对所欠信用卡和浦发银行债务不偿还、违反《上海浦东发展银行信用卡(个人卡)章程》
            及相关领用合约或本活动及其他相关规定的，浦发银行信用卡中心有权取消该客户参加本活动的资格。</p>
          <p>9、在法律法规许可范围内，浦发银行信用卡中心保留随时变更、调整、终止本活动及变更、调整本活动条款和细则的权利，
            前述调整于相关渠道公布后生效。活动相关问题请致电有鱼记账APP客服，咨询及投诉电话：4006556021。</p>
          <p>10、未尽事宜仍同时受《上海浦东发展银行信用卡（个人卡）章程》及相关领用合约等文件约束。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PufaRule',
  mounted() {
    document.title = '浦发银行活动页';
  },
};
</script>
<style lang="scss" scoped>
  .pufa-rule-page {
    max-width: 750px;
    background-image: url('//download-cos.yofish.com/youyu-gongjushiyebu/20220506152519270-2.png');
    background-repeat: repeat;
    font-family: SourceHanSansCN-Regular;
    padding-bottom: 80px;
    overflow-x: hidden;
    .head {
      width: 100%;
      > img {
        width: 100%;
      }
    }
    .content {
      margin-top: -180px;
      margin-left: 25px;
      margin-right: 18px;
      color: #fff;
      line-height: 61px;
      .activty-text {
        margin-bottom: 80px;
        position: relative;
        .red-bg {
          width: 96px;
          right: -22px;
          bottom: -100px;
          position: absolute;
          img {
            width: 100%;
          }
        }
        .star-icon {
          position: absolute;
          width: 30px;
          right: 120px;
          top: -20px;
          img {
            width: 100%;
          }
        }
      }
      .activty-title {
        font-size: 30px;
        font-weight: bold;
      }
      .text {
        font-size: 24px;
      }
    }
  }
</style>
